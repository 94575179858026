import React, { FunctionComponent } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";

export interface VideoTeaserInterface {
  videoTitle: string;
  video?: {
    asset: {
      url: string;
    };
  };
  videoAltText?: {
    asset: {
      url: string;
    };
  };
  _rawImage: ImageInterface;
  // For Preview
  image?: ImageInterface;
}

const VideoTeaser: FunctionComponent<VideoTeaserInterface> = ({ videoTitle, video, _rawImage, image, videoAltText }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const gradient = "linear-gradient(75deg, var(--pink-color) 0%, var(--purple-color))";
  const coverImage = _rawImage || image;
  const imageUrl = coverImage.asset.url + "?w=700&h=760&fm=webp";

  const sectionMargin = videoTitle.split(" ").length > 4 ? "video-teaser-small" : "video-teaser-large";

  return (
    <section className="video-teaser" style={{ background: gradient }} data-testid="video-teaser">
      <div className={sectionMargin}>
        <Container fluid>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <h1>{videoTitle}</h1>
              <div className="image-container">
                <div className="hero-image">
                  {video ? (
                    <video
                      width="auto"
                      height="auto"
                      muted
                      autoPlay
                      loop
                      playsInline
                      className="video"
                      data-testid="video-teaser-video"
                      poster={imageUrl}
                    >
                      <source src={video.asset.url} type="video/mp4" />
                      <meta itemProp="description" content={videoTitle} />
                      {videoAltText?.asset && <track src={videoAltText.asset.url} kind="captions" />}
                    </video>
                  ) : (
                    <picture data-testid="video-teaser-image">
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(_rawImage)
                            .auto("format")
                            .quality(80)
                            .width(700)
                            .height(720)
                            .format("webp")
                            .url() || undefined
                        }
                      />
                      <img
                        src={
                          urlBuilder
                            .image(_rawImage)
                            .auto("format")
                            .quality(80)
                            .width(530)
                            .height(890)
                            .format("webp")
                            .url() as string
                        }
                        alt={_rawImage.alt}
                      />
                    </picture>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default VideoTeaser;
